<template>
  <v-row>
    <v-col cols="12">
      <search-card
        @clicked="(term) => getItems(term, this.$route.query.page)"
      />
    </v-col>
    <v-col cols="12">
      <v-data-table
        :headers="headers"
        :items="items"
        :options.sync="options"
        :server-items-length="pagination.total"
        :loading="loading"
        class="elevation-1"
        hide-default-footer
      >
        <template v-slot:item.user_id="{ item }">
          <v-chip
            @click.stop="
              $router.push({
                name: 'dashboard-auth-user',
                params: { id: item.user_id },
                query: { parent_page: $route.query.page },
              })
            "
            small
            outlined
          >
            {{ (item.user ? item.user.display_name : "N/A").toUpperCase() }}
          </v-chip>
        </template>

        <template v-slot:item.data="{ item }">
          <div v-for="(val, key) in item.data" :key="key">
            <div
              :class="
                $store.getters.isThemeDark ? 'yellow--text' : 'blue--text'
              "
            >
              {{ key.length ? $lang(key).toUpperCase() : "NA" }}
            </div>
            <div>{{ val }}</div>
          </div>
        </template>
        <template v-slot:item.order_count="{ item }">
          {{ item.order_count }}
        </template>

        <template v-slot:item.created_at="{ item }">
          {{ item.created_at | formateDate }}
        </template>

        <template v-slot:item.amount="{ item }">
          {{ item.currency == "INR" ? "₹" : item.currency }}
          {{ item.provider == "razorpay" ? item.amount / 100 : item.amount }}
        </template>

        <template v-slot:item.active="{ item }">
          <v-btn
            @click.stop="handleActiveToggle(item)"
            icon
            :color="item.active ? 'green' : 'red'"
          >
            <v-icon>{{
              item.active ? "mdi-checkbox-marked" : "mdi-checkbox-blank-outline"
            }}</v-icon>
          </v-btn>
        </template>

        <template v-slot:item.actions="{ item }">
          <v-btn
            @click.stop="handleFeaturedToggle(item)"
            icon
            :color="item.featured ? 'green' : 'red'"
          >
            <v-icon>{{
              item.featured ? "mdi-star" : "mdi-star-outline"
            }}</v-icon>
          </v-btn>

          <v-btn
            class="mx-1"
            small
            icon
            @click="
              $router.push({
                name: 'dashboard-quotes-create',
                params: { id: item.id },
              })
            "
          >
            <v-icon>mdi-pencil</v-icon>
          </v-btn>
          <v-btn class="mx-1" small icon @click="handleDelete(item)">
            <v-icon>mdi-delete</v-icon>
          </v-btn>
        </template>
      </v-data-table>
    </v-col>
    <v-col cols="12">
      <query-page-redirect :pagination="pagination" />
    </v-col>
    <fab-button
      :icon="'mdi-plus'"
      color="primary"
      @clicked="$router.push({ name: 'dashboard-quotes-create' })"
    />
  </v-row>
</template>

<script>
import { mapGetters } from "vuex";
import FabButton from "@/components/FabButton.vue";
import { axios } from "@/plugins/axios";
import SearchCard from "../../prep/modules/SearchCard";
import QueryPageRedirect from "@/components/QueryPageRedirect.vue";
import bus from "@/bus";
import moment from "@/plugins/moment";

export default {
  name: "dashboard-quotes",
  components: {
    FabButton,
    SearchCard,
    QueryPageRedirect,
  },
  data() {
    return {
      items: [],
      options: {},
      loading: false,
      where: "",
      headers: [
        { text: "Active", value: "active", sortable: true },
        // { text: "Featured", value: "featured", sortable: true },
        { text: "Quote", value: "quote", sortable: true },
        { text: "Author", value: "author", sortable: true },
        { text: "Creator", value: "user_id", sortable: true },
        { text: "Time", value: "created_at", sortable: true },
        { text: "Actions", value: "actions", sortable: false },
      ],
      pagination: {
        lastPage: null,
        page: 1,
        perPage: null,
        total: null,
      },
    };
  },
  computed: {
    ...mapGetters({
      auth: "auth/auth",
    }),
  },
  filters: {
    formateDate(value) {
      return moment(String(value)).format("DD/MMM/YYYY hh:mm A");
    },
  },
  watch: {
    options: {
      handler() {
        this.getItems();
      },
      deep: true,
    },
    "pagination.page"(page) {
      if (page > 0) {
        //this.getItems();
      }
    },
  },
  methods: {
    handleDelete(item) {
      if (
        !confirm(`Are you sure to delete ${item.quote ? item.quote : item.id}?`)
      ) {
        return Promise.reject("CANCELED_BY_USER");
      }

      bus.$emit("showWait");
      this.loading = true;
      return axios
        .delete(`/quotes/${item.id}`)
        .then((res) => {
          this.items = this.items.filter((el) => el.id != item.id);
          return Promise.resolve(res);
        })
        .catch((error) => {
          return Promise.reject(error.response);
        })
        .finally(() => {
          this.loading = false;
          bus.$emit("hideWait");
        });
    },
    handleActiveToggle(item) {
      //bus.$emit("showWait");
      this.loading = true;
      return axios
        .get(`/quotes/toggle/${item.id}`)
        .then((res) => {
          item.active = !item.active;
          return Promise.resolve(res.data);
        })
        .catch((error) => {
          return Promise.reject(error.response);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    handleFeaturedToggle(item) {
      //bus.$emit("showWait");
      this.loading = true;
      return axios
        .get(`/quotes/featured/${item.id}`)
        .then((res) => {
          item.featured = !item.featured;
          return Promise.resolve(res.data);
        })
        .catch((error) => {
          return Promise.reject(error.response);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    getItems(term = "", currPage) {
      if (!currPage) {
        currPage = this.$route.query.page;
      }
      this.where = term;
      this.loading = true;
      bus.$emit("showWait");
      const { sortBy, sortDesc } = this.options;
      const ascDesc = sortDesc && sortDesc[0] ? "desc" : "asc";

      return axios
        .get(`/quotes`, {
          params: {
            page: currPage ? currPage : 1,
            orderby:
              sortBy && sortBy.length > 0 ? `${sortBy[0]}|${ascDesc}` : ``,
            where: this.where,
            perPage: 20,
          },
        })
        .then((res) => {
          this.pagination = {
            lastPage: res.data.lastPage,
            page: res.data.page,
            perPage: res.data.perPage,
            total: res.data.total,
          };
          this.items = res.data.data;
          return Promise.resolve(res.data);
        })
        .catch((error) => {
          return Promise.reject(error.response);
        })
        .finally(() => {
          this.loading = false;
          bus.$emit("hideWait");
        });
    },
  },
  created() {
    // this.getItems("", this.$route.query.page);
  },
  updated() {
    bus.$emit("highlightElement");
  },
};
</script>
